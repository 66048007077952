import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import { AiOutlineHome, AiOutlineFileText, AiOutlineCreditCard, AiOutlineUser } from 'react-icons/ai';

const BottomNav = () => {
    const location = useLocation();

    const navItems = [
        { name: 'Home', path: '/dashboard', icon: AiOutlineHome },
        { name: 'Invoices', path: '/dashboard/invoices', icon: AiOutlineFileText },
        { name: 'Payments', path: '/dashboard/payments', icon: AiOutlineCreditCard },
        { name: 'Account', path: '/dashboard/settings', icon: AiOutlineUser },
    ];

    const isActive = (path) => location.pathname === path;

    return (
        <div className="fixed bottom-0 left-0 w-full bg-white border-t border-gray-200 shadow-sm z-50 md:hidden">
            <div className="flex justify-around items-center py-4">
                {navItems.map((item) => {
                    const Icon = item.icon;
                    const active = isActive(item.path);
                    return (
                        <Link
                            to={item.path}
                            key={item.name}
                            className={`flex flex-col items-center text-center transition-all duration-300 ${active ? 'text-indigo-600' : 'text-gray-500'
                                }`}
                        >
                            <div
                                className={`p-2 rounded-lg transition-transform duration-300 ${active ? 'bg-indigo-100' : ''
                                    }`}
                            >
                                <Icon className={`w-6 h-6 ${active ? 'text-indigo-600' : 'text-gray-500'}`} />
                            </div>
                            <span
                                className={`mt-1 text-xs font-medium transition-colors duration-300 ${active ? 'text-indigo-600' : 'text-gray-500'
                                    }`}
                            >
                                {item.name}
                            </span>
                        </Link>
                    );
                })}
            </div>
        </div>
    );
};

export default BottomNav;
