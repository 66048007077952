import React, { useEffect, useState } from 'react';
import Layout from '../elements/Layout';
import { IoMdSearch } from 'react-icons/io';
import Cookies from 'js-cookie';
import { fetchCustomerProperties } from '../utils/api/api';
import toast from 'react-hot-toast';
import Loading from '../elements/Loading';
import Modal from '../elements/Modal';

const MyProperties = () => {
    const [properties, setProperties] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedProperty, setSelectedProperty] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const customer_id = Cookies.get('user_id');

    const getProperties = async () => {
        setLoading(true);
        try {
            const response = await fetchCustomerProperties(customer_id);
            setProperties(response.properties);
        } catch (error) {
            toast.error("Failed to get properties");
        } finally {
            setLoading(false);
        }
    };

    const openModal = (property) => {
        setSelectedProperty(property);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedProperty(null)
    };

    useEffect(() => {
        getProperties();
    }, []);

    return (
        <Layout text="My Properties">
            {loading && <Loading />}
            <div className="hidden md:flex w-full justify-between items-center mb-4">
                <div className="w-fit flex items-center gap-2 px-4 py-1.5 rounded-full bg-white border border-gray-200">
                    <IoMdSearch className='text-gray-600' />
                    <input
                        type="text"
                        placeholder='Search'
                        className='outline-none border-none text-gray-600 font-light text-[14px]'
                    />
                </div>
            </div>

            <div className="w-full bg-white rounded-lg md:mt-4 p-4">
                {/* Property Cards */}
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
                    {properties.length > 0 ? (
                        properties.map((property, index) => (
                            <div
                                key={property.id}
                                className="bg-white p-4 rounded-lg shadow-md hover:shadow-lg transition duration-300 ease-in-out cursor-pointer"
                                onClick={() => openModal(property)}
                            >
                                <div className="text-lg font-semibold mb-3">
                                    {property.property?.title_number || 'N/A'}
                                </div>
                                <div className="text-sm mb-2">
                                    <strong>Property Number:</strong> {property.property?.property_number || 'N/A'}
                                </div>
                                <div className="text-sm mb-2">
                                    <strong>Status:</strong> {property.property?.status || 'N/A'}
                                </div>
                                <div className="text-sm mb-2">
                                    <strong>Amount:</strong> KES {Number(property.amount).toLocaleString()}
                                </div>
                                <div className="text-sm mb-2">
                                    <strong>Payment Status:</strong> {property.payment_status}
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className="col-span-full text-center text-gray-600">
                            No properties found.
                        </div>
                    )}
                </div>
            </div>

            {/* Modal with Detailed Information */}
            {selectedProperty && (
                <Modal isOpen={isModalOpen} onClose={closeModal} title="More Details">
                    <div className="space-y-3 text-gray-700">
                        <p className="text-sm">
                            <span className="font-semibold">Property Number:</span> {selectedProperty.property?.property_number || 'N/A'}
                        </p>
                        <p className="text-sm">
                            <span className="font-semibold">Title Number:</span> {selectedProperty.property?.title_number || 'N/A'}
                        </p>
                        <p className="text-sm">
                            <span className="font-semibold">Size:</span> {selectedProperty.property?.size || 'N/A'}
                        </p>
                        <p className="text-sm">
                            <span className="font-semibold">Status:</span> {selectedProperty.property?.status || 'N/A'}
                        </p>
                        <p className="text-sm">
                            <span className="font-semibold">Amount:</span> KES {Number(selectedProperty.amount).toLocaleString()}
                        </p>
                        <p className="text-sm">
                            <span className="font-semibold">Payment Type:</span> {selectedProperty.payment_type}
                        </p>
                        <p className="text-sm">
                            <span className="font-semibold">Payment Status:</span> {selectedProperty.payment_status}
                        </p>
                    </div>

                    <div className="flex justify-end mt-6">
                        <button
                            onClick={closeModal}
                            className="py-2 px-6 bg-secondary text-white font-medium rounded-md shadow-sm hover:bg-secondary-dark transition duration-300 ease-in-out"
                        >
                            Close
                        </button>
                    </div>
                </Modal>
            )}
        </Layout>
    );
};

export default MyProperties;