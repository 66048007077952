import React, { useEffect, useState, useRef } from 'react';
import { FaDownload } from 'react-icons/fa';
import Layout from '../elements/Layout';
import { useParams } from 'react-router-dom';
import { fetchSingleContract } from '../utils/api/api';
import toast from 'react-hot-toast';
import Loading from '../elements/Loading';
import SalesAgreement from '../minicomponents/SalesAgreement';
import Certificate from '../minicomponents/Certificate';
import OfferLetter from '../minicomponents/OfferLetter';
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const ViewContract = () => {
    const [contract, setContract] = useState({});
    const [loading, setLoading] = useState(false);
    const { id } = useParams();

    const getContract = async () => {
        setLoading(true);
        try {
            const response = await fetchSingleContract(id);
            setContract(response.contract);
        } catch (error) {
            toast.error("Failed to get contract");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getContract();
    }, [id]);

    const pdfRef = useRef();

    const handleDownload = () => {
        const element = pdfRef.current;

        if (!element) {
            console.error("PDF reference not found.");
            return;
        }

        const filename = `_${contract?.customer?.first_name || "Customer"}_${contract?.customer?.last_name || "Name"}.pdf`;

        // Temporarily style the element to force desktop dimensions
        const originalStyle = {
            width: element.style.width,
            height: element.style.height,
            transform: element.style.transform,
            transformOrigin: element.style.transformOrigin,
        };

        element.style.width = "1024px"; // Force a desktop width
        element.style.height = "auto";
        element.style.transform = "scale(1)";
        element.style.transformOrigin = "top left";

        html2canvas(element, {
            scale: 2, // High resolution for better quality
            scrollX: 0,
            scrollY: 0,
            width: element.scrollWidth,
            height: element.scrollHeight,
        })
            .then((canvas) => {
                // Restore original styles
                element.style.width = originalStyle.width;
                element.style.height = originalStyle.height;
                element.style.transform = originalStyle.transform;
                element.style.transformOrigin = originalStyle.transformOrigin;

                const pdf = new jsPDF({
                    unit: "in",
                    format: "letter", // US Letter size
                    orientation: "portrait",
                });

                const imgData = canvas.toDataURL("image/jpeg", 0.98);
                const pdfWidth = pdf.internal.pageSize.getWidth();
                const pdfHeight = pdf.internal.pageSize.getHeight();

                const imgHeight = (canvas.height * pdfWidth) / canvas.width;
                let heightLeft = imgHeight;
                let position = 0;

                pdf.addImage(imgData, "JPEG", 0, position, pdfWidth, imgHeight);
                heightLeft -= pdfHeight;

                while (heightLeft > 0) {
                    position -= pdfHeight;
                    pdf.addPage();
                    pdf.addImage(imgData, "JPEG", 0, position, pdfWidth, imgHeight);
                    heightLeft -= pdfHeight;
                }

                pdf.save(filename);

                // Convert to Blob for Flutter
                const blob = pdf.output("blob");

                const reader = new FileReader();
                reader.onload = () => {
                    const base64data = reader.result.split(",")[1];

                    if (window.flutter_inappwebview) {
                        window.flutter_inappwebview.callHandler("onDownloadStarted", {
                            filename: filename,
                            base64: base64data,
                        });
                    }
                };

                reader.readAsDataURL(blob);
            })
            .catch((error) => {
                // Restore original styles in case of failure
                element.style.width = originalStyle.width;
                element.style.height = originalStyle.height;
                element.style.transform = originalStyle.transform;
                element.style.transformOrigin = originalStyle.transformOrigin;

                console.error("Error generating PDF:", error);

                if (window.flutter_inappwebview) {
                    window.flutter_inappwebview.callHandler("onDownloadFailed", error.message);
                }
            });
    };

    return (
        <Layout text={"View Contract"}>
            {loading && <Loading />}
            <div className="flex justify-end w-full items-center mb-4">
                <div className="space-x-2 bg-white px-4 py-1 rounded-md">
                    <button onClick={handleDownload} className="text-black">
                        <FaDownload className="inline-block mr-2" />
                    </button>
                </div>
            </div>

            <div ref={pdfRef} className="">
                {contract?.type === 'offer_letter' && (
                    <OfferLetter contract={contract} />
                )}
                {contract?.type === 'completion_letter' && (
                    <Certificate contract={contract} />
                )}
                {contract?.type === 'sales_agreement' && (
                    <SalesAgreement contract={contract} />
                )}
            </div>
        </Layout>
    );
};

export default ViewContract;           
