import React, { useEffect, useState } from 'react';
import Layout from '../elements/Layout';
import Cookies from 'js-cookie';
import { fetchCustomerOrders } from '../utils/api/api';
import toast from 'react-hot-toast';
import { FaEye } from 'react-icons/fa6';
import Loading from '../elements/Loading';
import { IoMdSearch } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';

const Orders = () => {
  const customer_id = Cookies.get('user_id');
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const navigate = useNavigate();

  const getOrders = async (page, pageSize) => {
    setLoading(true);
    const payload = {
      customer_id: customer_id,
      page: page,
      pageSize: pageSize,
    };
    try {
      const response = await fetchCustomerOrders(payload);
      setOrders(response.orders);
      setTotalPages(response.totalPages);
    } catch (error) {
      toast.error('Request failed');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getOrders(page, pageSize);
  }, [page, pageSize]);

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setPage(newPage);
    }
  };

  const handlePageSizeChange = (event) => {
    setPageSize(Number(event.target.value));
    setPage(1);
  };

  const handleMakePayment = (orderId) => {
    navigate(`/dashboard/orders/${orderId}/view`);
  };

  return (
    <Layout text="Pay now">
      {loading ? (
        <Loading />
      ) : (
        <div className="w-full bg-white rounded-lg p-4">
          {/* Search and Page Size Controls */}
          <div className="flex flex-col md:flex-row md:items-center md:justify-between w-full mb-4">
            <div className="w-full md:w-auto hidden md:flex md:items-center gap-3 bg-gray-100 px-4 py-2 rounded-lg shadow-sm">
              <IoMdSearch className="text-gray-500 text-lg" />
              <input
                type="text"
                placeholder="Search transactions"
                className="w-full outline-none border-none bg-transparent text-sm text-gray-700"
              />
            </div>

            <div className="flex items-center mt-3 md:mt-0">
              <label htmlFor="pageSize" className="text-sm text-gray-600 mr-2">
                Page size:
              </label>
              <select
                id="pageSize"
                value={pageSize}
                onChange={handlePageSizeChange}
                className="border border-gray-300 rounded-lg text-sm py-2 px-4 bg-white focus:ring-2 focus:ring-indigo-500 focus:outline-none"
              >
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
              </select>
            </div>
          </div>

          {/* Orders Grid Layout */}
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
            {orders.length > 0 ? (
              orders.map((order) => (
                <div key={order.id} className="bg-white shadow-lg rounded-lg p-4 flex flex-col">
                  <div className="text-xl font-semibold mb-4">
                    {Number(order.amount).toLocaleString('en-KE')} KES
                  </div>
                  <div className="mb-4">
                    <span
                      className={`px-3 py-1 rounded-full text-sm ${order.payment_status === 'paid'
                        ? 'bg-green-100 text-green-600'
                        : 'bg-yellow-100 text-yellow-600'
                        }`}
                    >
                      {order.payment_status}
                    </span>
                  </div>
                  <div className="flex-1">
                    {order.payment_status !== 'paid' ? (
                      <button
                        onClick={() => handleMakePayment(order.id)}
                        className="px-4 py-2 text-white bg-indigo-600 hover:bg-indigo-500 rounded-full text-sm transition"
                      >
                        Pay Now
                      </button>
                    ) : (
                      <button
                        onClick={() => handleMakePayment(order.id)}
                        className="flex items-center gap-2 text-indigo-600 text-sm"
                      >
                        View Details
                        <FaEye className="text-indigo-500" size={16} />
                      </button>
                    )}
                  </div>
                </div>
              ))
            ) : (
              <div className="col-span-full text-center text-gray-500">
                No orders found.
              </div>
            )}
          </div>

          {/* Pagination */}
          <div className="flex items-center justify-between mt-6">
            <div className="text-sm text-gray-500">
              Page {page} of {totalPages}
            </div>
            <div className="flex items-center gap-3">
              <button
                className={`px-4 py-2 rounded-md text-sm ${page === 1
                  ? 'bg-gray-200 text-gray-400 cursor-not-allowed'
                  : 'bg-indigo-100 text-indigo-600 hover:bg-indigo-200'
                  }`}
                onClick={() => handlePageChange(page - 1)}
                disabled={page === 1}
              >
                Previous
              </button>
              <button
                className={`px-4 py-2 rounded-md text-sm ${page === totalPages
                  ? 'bg-gray-200 text-gray-400 cursor-not-allowed'
                  : 'bg-indigo-100 text-indigo-600 hover:bg-indigo-200'
                  }`}
                onClick={() => handlePageChange(page + 1)}
                disabled={page === totalPages}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default Orders;
