import React, { useEffect, useState } from 'react';
import Layout from '../elements/Layout';
import { IoMdSearch } from 'react-icons/io';
import { CiFilter } from 'react-icons/ci';
import { FaArrowUpFromBracket } from 'react-icons/fa6';
import { acceptBooking, fetchCustomerBookings } from '../utils/api/api';
import toast from 'react-hot-toast';
import Cookies from 'js-cookie';
import Loading from '../elements/Loading';
import CustomBookingForm from '../components/CustomBookingForm';
import Modal from '../elements/Modal';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

const Bookings = () => {
  const [myBookings, setMyBookings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setopen] = useState(false);
  const customerId = Cookies.get('user_id');
  const [date, setDate] = useState(new Date());

  const onChange = (newDate) => {
    setDate(newDate);
  };

  const getMyBookings = async () => {
    setLoading(true);
    try {
      const response = await fetchCustomerBookings(customerId);
      setMyBookings(response.bookings);
    } catch (error) {
      toast.error("Failed to get bookings");
    } finally {
      setLoading(false);
    }
  };

  const handleAcceptBooking = async (bookingId) => {
    setLoading(true);
    try {
      const data = {
        customer_id: customerId,
        booking_id: bookingId,
      };
      await acceptBooking(data);
      toast.success("Booking accepted");
      getMyBookings();
    } catch (error) {
      toast.error("Failed to accept booking");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getMyBookings();
  }, []);

  const upcomingBookings = myBookings.filter(booking => booking.accepted_bookings.length > 0 || booking.is_custom);
  const nonCustomBookings = myBookings.filter(booking => !booking.is_custom);
  console.log(upcomingBookings)

  return (
    <Layout text="Bookings" backgroundColor="bg-white">
      {loading && <Loading />}
      <div className="hidden md:flex w-full justify-between items-center mb-4">
        <div className="w-[250px] md:w-fit hidden md:flex items-center gap-2 px-4 py-1.5 rounded-md bg-[#F7F7F7] border border-gray-200">
          <IoMdSearch className='text-gray-600' />
          <input
            type="text"
            placeholder='Search'
            className='w-fit outline-none border-none bg-transparent text-gray-600 font-light text-[14px]'
          />
        </div>
        <div className="flex items-center gap-3">
          <button className="border text-secondary text-[14px] font-medium items-center border-secondary px-4 py-1.5 rounded-md hidden md:flex gap-2">
            <CiFilter />
            Filter
          </button>
        </div>
      </div>

      <div className="flex w-full items-center justify-between -mb-2">
        <p className="block text-black font-medium text-[18px]">Site visits</p>
        <button
          onClick={() => setopen(true)}
          className="bg-secondary text-white w-fit text-[14px] rounded-md px-6 py-1.5"
        >
          Custom booking
        </button>
      </div>

      <div className="flex mt-4 flex-col md:flex-row gap-6 w-full">
        <div className="w-full md:w-[60%] flex flex-col gap-2">
          <div className="flex flex-col gap-3">
            {nonCustomBookings.map((booking) => (
              <div key={booking.id} className="flex w-full gap-3 items-center flex-col md:flex-row border-b dashed border-gray-300 pb-4">
                <div className="flex flex-col gap-3">
                  <p className="font-medium text-[18px] text-black">{booking?.project?.title} - {booking?.project?.location}</p>
                  <p className="text-[14px] text-gray-600">{booking.date} {booking.time}</p>
                  <p className="text-[14px] font-light text-black overflow-hidden text-ellipsis break-words max-h-[9rem] leading-[1.5rem]">{booking.description}</p>
                  <div className="flex gap-4 items-center">
                    <button
                      className={`bg-secondary text-white w-fit text-[14px] rounded-md px-6 py-1.5 ${booking.accepted_bookings.length > 0 ? 'opacity-50 cursor-not-allowed' : ''}`}
                      onClick={() => handleAcceptBooking(booking.id)}
                      disabled={booking.accepted_bookings.length > 0}
                    >
                      {booking.accepted_bookings.length > 0 ? 'Booking accepted' : 'Accept booking'}
                    </button>
                    <button onClick={() => setopen(true)} className="border border-secondary text-[14px] px-6 py-1.5 text-secondary font-medium rounded-md">
                      Custom booking
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="w-full md:w-[40%] flex flex-col">
          <div className="flex flex-col gap-4">
            <div className="flex flex-col p-4 rounded-lg border border-gray-300">
              <p className='text-[19px] font-medium text-black'>Your upcoming site visits</p>
              <div className="flex flex-col p-3">
                {upcomingBookings?.length > 0 ? (
                  upcomingBookings?.map((booking) => (
                    <div key={booking?.id} className="mb-4">
                      <p className="my-2 text-black text-[17px]">{booking?.project?.title}, {booking?.project?.location}</p>
                      <div className="flex flex-col mb-2">
                        <p className="text-gray-600 text-[13px]">Location</p>
                        <p className="text-black text-[14px]">{booking?.project?.location}</p>
                      </div>
                      <div className="flex items-center gap-6 flex-col md:flex-row">
                        <div className="flex flex-col">
                          <p className="text-gray-600 text-[13px]">Date</p>
                          <p className="text-black text-[14px]">{booking?.date}</p>
                        </div>
                        <div className="flex flex-col">
                          <p className="text-gray-600 text-[13px]">Time</p>
                          <p className="text-black text-[14px]">{booking?.time}</p>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <p>No upcoming site visits.</p>
                )}
              </div>
            </div>
            <div className="flex flex-col">
              <div className="w-full bg-white rounded-lg">
                <Calendar
                  onChange={onChange}
                  value={date}
                  className="rounded-lg bg-secondary"
                  tileClassName={({ date, view }) =>
                    date.toDateString() === new Date().toDateString()
                      ? 'bg-secondary text-black rounded-full'
                      : ''
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {open && (
        <Modal title="Create custom booking" onClose={() => setopen(false)}>
          <CustomBookingForm />
        </Modal>
      )}
    </Layout>
  );
};

export default Bookings;
