import React, { useContext, useEffect, useState } from 'react';
import Layout from '../elements/Layout';
import moment from 'moment';
import { IoMdSad, IoMdSearch } from 'react-icons/io';
import Modal from '../elements/Modal';
import { createTicket, fetchCustomerTickets } from '../utils/api/api';
import toast from 'react-hot-toast';
import { AuthContext } from '../utils/context/AuthContext';
import Cookies from 'js-cookie';
import Loading from '../elements/Loading';

const Support = () => {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tickets, setTickets] = useState([]);
  const [formData, setFormData] = useState({
    category: '',
    title: '',
    description: '',
    files: null,
  });

  const { user } = useContext(AuthContext);
  const id = Cookies.get('user_id')

  const getTickets = async () => {
    setLoading(true)
    try {
      const response = await fetchCustomerTickets(id);
      setTickets(response.tickets);
    } catch (error) {
      toast.error("Failed to get tickets");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getTickets();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      files: e.target.files,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const ticketData = new FormData();
    ticketData.append('category', formData.category);
    ticketData.append('title', formData.title);
    ticketData.append('description', formData.description);
    ticketData.append('customer_id', id);

    if (formData.files) {
      for (let i = 0; i < formData.files.length; i++) {
        ticketData.append('attachments', formData.files[i]);
      }
    }

    try {
      setLoading(true);
      await createTicket(ticketData);
      setShowModal(false);
      toast.success('Ticket created');
      window.location.reload();
    } catch (error) {
      toast.error("Failed to create ticket")
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout text="Support" backgroundColor='bg-[#FCFCFC]'>
      {loading && <Loading />}

      <div className="flex w-full justify-between items-center mb-4">
        <div className="w-fit hidden md:flex items-center gap-2 px-4 py-1.5 rounded-full bg-white border border-gray-200">
          <IoMdSearch className='text-gray-600' />
          <input
            type="text"
            placeholder='Search'
            className='outline-none border-none text-gray-600 font-light text-[14px]'
          />
        </div>
        <div className="flex items-center gap-3">
          <button
            className="bg-secondary rounded-md px-4 py-1.5 text-white font-medium text-[14px] flex items-center gap-2"
            onClick={() => setShowModal(true)}
          >
            Raise ticket
          </button>
        </div>
      </div>

      <div className="flex flex-col gap-3 w-full">
        {!loading && (!tickets || tickets.length === 0) ? (
          <div className="flex flex-col items-center justify-center py-12 bg-white shadow-md">
            <IoMdSad className="text-6xl text-gray-400 mb-4" />
            <p className="text-gray-600 text-lg font-medium">No tickets found</p>
            <p className="text-gray-400 text-sm mt-2">It seems like you haven't raised any support tickets yet.</p>
          </div>
        ) : (
          tickets.map((ticket, index) => (
            <div key={index} className="flex flex-col bg-white rounded-md p-4 shadow-sm gap-2">
              <div className="w-full flex items-center justify-between">
                <p className="text-[16px]">{ticket.category}</p>
                <p className={`${ticket.resolved ? 'text-green-500 bg-green-100' : 'text-secondary bg-yellow-100'}  text-[14px] font-medium px-3 py-1 rounded-full`}>{ticket.resolved ? 'Resolved' : 'Pending'}</p>
              </div>
              <p className="text-gray-600 text-[13px]">Id: {ticket.id} <span>Created: {moment(ticket.createdAt).format('MMM Do YYYY, h:mm a')}</span></p>
              <p className="text-[18px] font-medium">{ticket.title}</p>
              <p className="text-[14px] text-black font-light">{ticket.description}</p>
            </div>
          ))
        )}
      </div>

      {showModal && (
        <Modal onClose={() => setShowModal(false)} title="Add New Ticket">
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="block text-black text-[14px] font-medium mb-1" htmlFor="category">
                Category <span className="text-secondary">*</span>
              </label>
              <select
                id="category"
                name="category"
                value={formData.category}
                onChange={handleInputChange}
                className="border w-full border-gray-200 text-gray-600 font-light text-[12px] bg-transparent rounded-md p-2 outline-none focus:border-secondary"
              >
                <option value="">Select category</option>
                <option value="finance">Finance</option>
                <option value="booking">Booking</option>
                <option value="contract">Contract</option>
              </select>
            </div>
            <div className="mb-4">
              <label className="block text-black text-[14px] font-medium mb-1" htmlFor="title">
                Title <span className="text-secondary">*</span>
              </label>
              <input
                id="title"
                name="title"
                type="text"
                placeholder="Enter title"
                value={formData.title}
                onChange={handleInputChange}
                className="border w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
              />
            </div>
            <div className="mb-4">
              <label className="block text-black text-[14px] font-medium mb-1" htmlFor="description">
                Description <span className="text-secondary">*</span>
              </label>
              <textarea
                id="description"
                name="description"
                placeholder="Enter description"
                value={formData.description}
                onChange={handleInputChange}
                className="border w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                rows="5"
              ></textarea>
            </div>
            <div className="mb-4">
              <label className="block text-black text-[14px] font-medium mb-1" htmlFor="files">
                Attach files (optional)
              </label>
              <input
                id="files"
                type="file"
                multiple
                className="w-full border-gray-200 text-gray-600 font-light text-[12px] p-2 outline-none focus:border-secondary cursor-pointer file:mr-2 file:py-1 file:px-4 file:rounded-md file:border-0 file:text-sm file:bg-secondary file:text-white hover:file:bg-secondary-dark"
                onChange={handleFileChange}
              />
            </div>
            <div className="w-full flex items-center gap-2 justify-end">
              <button
                type="button"
                className="text-secondary font-medium text-[14px]"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={loading}
                className={`rounded-md bg-secondary text-white text-[13px] font-medium px-6 py-1.5 ${loading ? 'cursor-not-allowed bg-opacity-50' : ''}`}
              >
                {loading ? 'processing...' : 'Submit'}
              </button>
            </div>
          </form>
        </Modal>
      )}
    </Layout>

  );
};

export default Support;
