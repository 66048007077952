import React, { useEffect, useState } from 'react'
import { fetchProjects } from '../utils/api/api';
import toast from 'react-hot-toast';
import Layout from '../elements/Layout';
import { Link } from 'react-router-dom';
import { IoMdSearch } from 'react-icons/io';
import Loading from '../elements/Loading';

const HousingProjects = () => {
    const [projects, setProjects] = useState([]);
    const [loading, setLoading] = useState(false);
    const getProjects = async () => {
        setLoading(true);
        try {
            const data = await fetchProjects();
            setProjects(data.projects);
            setLoading(false);
        } catch (err) {
            toast.error('Failed to fetch Projects');
            setLoading(false);
        }
    };

    useEffect(() => {
        getProjects();
    }, []);
    return (
        <Layout text="Projects">
            {loading && <Loading />}
            <p className="flex md:hidden -mt-4 mb-2 font-medium text-[18px] text-black">Projects</p>
            <div className="hidden md:flex w-full justify-between mb-4 ">
                <div className="w-fit flex items-center gap-2 px-4 py-1.5 rounded-full bg-white border border-gray-200">
                    <IoMdSearch className='text-gray-600' />
                    <input
                        type="text"
                        placeholder='Search'
                        className='outline-none border-none text-gray-600 font-light text-[14px]'
                    />
                </div>
                <button className="bg-secondary rounded-md px-4 py-1.5 text-white font-medium text-[14px] flex items-center gap-2 w-fit">
                    Filter
                </button>
            </div>
            <div className="w-full grid grid-cols-2 md:grid-cols-4 gap-4 lg:grid-cols-4">
                {projects.length === 0 ? (
                    <></>
                ) : (
                    projects.map((project) => (
                        <Link to={`/dashboard/projects/${project.id}/view`} className="flex flex-col gap-2 bg-white rounded-md shadow-sm hover:shadow-md p-4">
                            <img
                                src={project?.images?.file || 'https://imgs.search.brave.com/L0mOyBBDOxVD6jmTiwNh93bAa_8D_KweRLE_oYnq-Kg/rs:fit:500:0:0:0/g:ce/aHR0cHM6Ly9jZG4u/dmVjdG9yc3RvY2su/Y29tL2kvcHJldmll/dy0xeC82MS80Ni9n/YWxsZXJ5LWljb24t/c2lsaG91ZXR0ZS1v/Zi1hbi1pbWFnZS1w/aG90by1hbGJ1bS12/ZWN0b3ItNDU0MzYx/NDYuanBn'}
                                alt={project.title}
                                className="w-full h-40 object-cover rounded-lg mb-4"
                            />
                            <div className="flex flex-col gap-2">
                                <p className="font-medium text-[18px] truncate">{project?.title}</p>
                                <p className="text-gray-600 -mt-1 text-[14px]">{project.location}</p>
                                <p className="text-[14px] text-secondary font-medium">View</p>
                            </div>
                        </Link>
                    ))
                )}
            </div>
        </Layout>
    )
}

export default HousingProjects