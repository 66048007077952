import React, { useEffect, useState } from 'react';
import Layout from '../elements/Layout';
import { IoMdSearch } from 'react-icons/io';
import { Link, useNavigate } from 'react-router-dom';
import { fetchCustomerInvoices } from '../utils/api/api';
import toast from 'react-hot-toast';
import Cookies from 'js-cookie';
import Loading from '../elements/Loading';
import moment from 'moment';

const Invoices = () => {
  const navigate = useNavigate();
  const customer_id = Cookies.get('user_id');
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [invoicesPerPage] = useState(40);
  const [totalPages, setTotalPages] = useState(1);
  const [filteredInvoices, setFilteredInvoices] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const getInvoices = async (page, size) => {
    setLoading(true);
    try {
      const response = await fetchCustomerInvoices({ customer_id, page, pageSize: size });
      setInvoices(response.invoices);
      setFilteredInvoices(response.invoices);
      setTotalPages(response.meta.totalPages);
    } catch (error) {
      toast.error('Failed to get invoices');
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const formatInvoiceNumber = (invoiceNumber) => {
    const yearSuffix = moment().format('YY');
    return `MVL-${invoiceNumber}-${yearSuffix}`;
  };

  useEffect(() => {
    getInvoices(currentPage, invoicesPerPage);
  }, [currentPage, invoicesPerPage]);

  const handleSearch = (event) => {
    const term = event.target.value;
    setSearchTerm(term);
    const filtered = invoices.filter((invoice) => {
      return (
        invoice.id.toString().includes(term) ||
        invoice.order.property.title_number.includes(term) ||
        invoice.serial_no.includes(term)
      );
    });
    setFilteredInvoices(filtered);
  };

  const truncateText = (text, limit) => {
    if (!text) return '';
    const words = text.split(' ');
    return words.length > limit ? `${words.slice(0, limit).join(' ')}...` : text;
  };

  const indexOfLastInvoice = currentPage * invoicesPerPage;
  const paginate = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  return (
    <Layout text="Invoices" backgroundColor="bg-white">
      {loading && <Loading />}
      <p className="-mt-6 mb-2 font-medium text-primary text-[20px] md:hidden">Invoices</p>
      {/* <div className="flex w-full justify-between items-center mb-6">
        <div className="w-fit flex items-center gap-2 px-4 py-1.5 rounded-full bg-gray-100 border border-gray-300">
          <IoMdSearch className="text-gray-500" />
          <input
            type="text"
            placeholder="Search"
            value={searchTerm}
            onChange={handleSearch}
            className="outline-none border-none text-gray-600 font-light text-[14px] bg-transparent"
          />
        </div>
      </div> */}

      <div className="hidden md:block w-full bg-white rounded-lg shadow-md">
        <div className="overflow-x-auto rounded-lg">
          <table className="min-w-full bg-white h-full rounded-lg border border-gray-200 table-auto">
            <thead>
              <tr className="text-gray-600 border-b pb-4 text-[14px] py-3 bg-[#F7F7F7] font-medium">
                <th className="py-3 px-6 text-left">Invoice No.</th>
                <th className="py-3 px-6 text-left">Title Number</th>
                <th className="py-3 px-6 text-left">Serial No.</th>
                <th className="py-3 px-6 text-left">Payment Status</th>
                <th className="py-3 px-6 text-left">Due Date</th>
                <th className="py-3 px-6 text-left">Amount</th>
              </tr>
            </thead>
            <tbody>
              {filteredInvoices.length > 0 ? (
                filteredInvoices.map((invoice, index) => (
                  <tr
                    key={invoice.invoiceNumber}
                    onClick={() => navigate(`/dashboard/invoices/${invoice.id}/view`)}
                    className={`text-[14px] text-gray-700 border-b border-gray-200 cursor-pointer hover:bg-gray-100 transition duration-150 ease-in-out ${index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}`}
                  >
                    <td className="py-4 px-6 text-left">
                      {formatInvoiceNumber(invoice.id)}
                    </td>
                    <td className="py-4 px-6 text-left">
                      {invoice?.order?.property?.title_number}
                    </td>
                    <td className="py-4 px-6 text-left">
                      {invoice.serial_no}
                    </td>
                    <td className="py-4 px-6 text-left">
                      {new Date(invoice.payment_due_date) < new Date() && invoice.payment_status !== 'paid' ? (
                        <span className="px-2 py-1 rounded-md text-[12px] bg-red-100 text-red-700">
                          Overdue
                        </span>
                      ) : (
                        <span className={`px-2 py-1 rounded-md text-[12px] ${invoice.payment_status === 'paid' ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'}`}>
                          {invoice.payment_status}
                        </span>
                      )}
                    </td>
                    <td className="py-4 px-6 text-left">
                      {moment(invoice.payment_due_date).format('MMM DD, YYYY')}
                    </td>
                    <td className="py-4 px-6 text-left">
                      KES {Number(invoice.total_amount).toLocaleString()}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6" className="py-4 px-6 text-center text-gray-600 text-[14px]">
                    No invoices found.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <div className="md:hidden">
        {loading ? (
          <Loading />
        ) : filteredInvoices.length > 0 ? (
          filteredInvoices.map((invoice, index) => (
            <Link to={`/dashboard/invoices/${invoice.id}/view`} key={invoice.id} className="flex border bg-white p-3 mt-2 rounded-lg items-center justify-between">
              <div className="flex items-center gap-2">
                <div className="bg-green-500 w-[40px] flex items-center justify-center h-[40px] text-white p-2 rounded-full">
                  {index + 1}
                </div>
                <div className="flex flex-col">
                  <p className="text-[14px] text-primary">{formatInvoiceNumber(invoice.id)}</p>
                  <p className="text-[11px] text-gray-600">{truncateText(invoice?.order?.property?.title_number, 3)}</p>
                </div>
              </div>
              <div className="flex flex-col text-end">
                <p className="font-medium text-primary text-[13px]">KES {Number(invoice.total_amount).toLocaleString()}</p>
                <p className="text-gray-500 text-[11px]">{moment(invoice.payment_due_date).format('MMM Do, YYYY')}</p>
              </div>
            </Link>
          ))
        ) : (
          <div className="text-center text-gray-600">No invoices found.</div>
        )}
      </div>

      {filteredInvoices.length > 0 && (
        <div className="w-full flex justify-between mt-4">
          <button
            onClick={() => paginate(currentPage - 1)}
            disabled={currentPage === 1}
            className="text-sm flex items-center text-primary font-light gap-2 disabled:opacity-50"
          >
            Prev
          </button>

          <div className="flex items-center">
            {[...Array(totalPages)].map((_, i) => {
              const page = i + 1;
              return (
                <button
                  key={page}
                  onClick={() => paginate(page)}
                  className={`px-3 py-1.5 mx-1 text-sm font-medium ${currentPage === page ? 'bg-primary text-white' : 'text-primary'} rounded-md`}
                >
                  {page}
                </button>
              );
            })}
          </div>

          <button
            onClick={() => paginate(currentPage + 1)}
            disabled={currentPage === totalPages}
            className="text-sm flex items-center text-primary font-light gap-2 disabled:opacity-50"
          >
            Next
          </button>
        </div>
      )}
    </Layout>
  );
};

export default Invoices;
