import React, { useContext, useEffect, useState } from 'react';
import Layout from '../elements/Layout';
import { IoMdSearch } from "react-icons/io";
import { fetchCustomerStats } from '../utils/api/api';
import toast from 'react-hot-toast';
import Loading from '../elements/Loading';
import Cookies from 'js-cookie';
import { AuthContext } from '../utils/context/AuthContext';
import { Link } from 'react-router-dom';
import WalletBalance from '../minicomponents/WalletBalance';

const Dashboard = () => {
  const [stats, setStats] = useState([]);
  const [loading, setLoading] = useState(false);
  const { user } = useContext(AuthContext);
  const customer_id = Cookies.get('user_id');

  const getStats = async () => {
    setLoading(true)
    try {
      const response = await fetchCustomerStats(customer_id);
      setStats(response.data);
    } catch (error) {
      toast.error("Failed to get stats")
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getStats()
  }, [])

  return (
    <Layout text="Dashboard" backgroundColor="bg-[#fff]">
      {loading && <Loading />}

      <div className="flex items-center justify-between w-full">
        <p className="text-lg font-medium text-gray-800">
          Hello, {user?.first_name}
        </p>
        <div className="w-fit flex items-center gap-3 px-4 py-2 rounded-full bg-gray-100">
          <IoMdSearch className="text-gray-500 text-xl" />
          <input
            type="text"
            placeholder="Search"
            className="outline-none border-none bg-transparent text-gray-600 font-light text-sm w-full"
          />
        </div>
      </div>

      <div className="mt-4 w-full flex flex-col md:flex-row gap-4">
        {[
          {
            label: "Properties Listed",
            value: stats?.properties,
            color: "bg-blue-50 text-blue-600 border-blue-100",
          },
          {
            label: "Your Purchases",
            value: stats?.my_properties,
            color: "bg-green-50 text-green-600 border-green-100",
          },
          {
            label: "In Progress",
            value: stats?.purchases_inProgress,
            color: "bg-yellow-50 text-yellow-600",
          },
          {
            label: "Contracts",
            value: stats?.contracts,
            color: "bg-indigo-50 text-indigo-600 border-indigo-100",
          },
        ].map((stat, idx) => (
          <div
            key={idx}
            className={`w-full rounded-lg p-5 flex flex-col gap-2 shadow-sm hover:shadow-md transition-shadow duration-300 ${stat.color}`}
          >
            <p className="text-sm">{stat.label}</p>
            <hr className="border-gray-200" />
            <p className="text-lg font-semibold">{stat.value}</p>
          </div>
        ))}
      </div>

      <div className="flex flex-col-reverse md:flex-row gap-6 mt-6 w-full">
        <div className="flex flex-col w-full md:w-[65%]">
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">Projects</h2>
          <div className="grid grid-cols-2 md:grid-cols-3 gap-3">
            {stats?.popular_listing?.map((property) => (
              <Link
                to={`/dashboard/projects/${property.id}/view`}
                key={property.id}
                className="rounded-lg p-4 hover:shadow-lg mb-2 transition-shadow duration-300 bg-gray-100"
              >
                <img
                  src={
                    property?.images?.file ||
                    "https://imgs.search.brave.com/L0mOyBBDOxVD6jmTiwNh93bAa_8D_KweRLE_oYnq-Kg/rs:fit:500:0:0:0/g:ce/aHR0cHM6Ly9jZG4u/dmVjdG9yc3RvY2su/Y29tL2kvcHJldmll/dy0xeC82MS80Ni9n/YWxsZXJ5LWljb24t/c2lsaG91ZXR0ZS1v/Zi1hbi1pbWFnZS1w/aG90by1hbGJ1bS12/ZWN0b3ItNDU0MzYx/NDYuanBn"
                  }
                  alt={property.title}
                  className="w-full h-40 object-cover rounded-lg mb-3"
                />
                <h3 className="text-lg text-gray-800 font-medium truncate">
                  {property.title} - {property.location}
                </h3>
                <p className="text-indigo-600 text-sm font-medium">View</p>
              </Link>
            ))}
          </div>
        </div>

        {/* Wallet Balance */}
        <WalletBalance />
      </div>
    </Layout>

  );
}

export default Dashboard;
